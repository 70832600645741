import React, { useState } from 'react';
import { Typography, InternetIcon, Stack, Tooltip, InfoIcon } from '@trustsecurenow/components-library';
import QuantityInput from './QuantityInput';

const AdminDomainsAllowed = ({
  domainsRange = {},
  domainsCount,
  isEBPP,
  onIncrementClick,
  onDecrementClick,
  onChangeDomainsCountInput,
  error
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isIncrementDisabled = domainsCount >= domainsRange.max;
  const isDecrementDisabled = domainsCount <= domainsRange.min;

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  return (
    <Stack direction="row" alignItems="flex-start" spacing={2.5} data-testid="adminDomainsAllowed">
      <Stack direction="row" spacing={1.25} alignItems="center" minHeight={40}>
        <InternetIcon sx={{ fontSize: 24 }} />
        <Typography variant="button">Domains Allowed</Typography>
        <Tooltip
          placement="top"
          onOpen={handleOpenTooltip}
          onClose={handleCloseTooltip}
          title={
            <>
              Represents the number of domains allowed to be added for this account. <br />
              {isEBPP ? 'EBPP' : 'Non-EBPP'} clients have a minimum of {domainsRange.min} domains allowed.
              <br />
              Once additional domains are allowed you will not be able to reduce them.
              <br />
            </>
          }
        >
          <Stack component="span" data-testid="infoIcon">
            <InfoIcon color={isTooltipOpen ? 'info' : 'default'} sx={{ fontSize: 16 }} />
          </Stack>
        </Tooltip>
      </Stack>
      <QuantityInput
        isDecrementDisabled={isDecrementDisabled}
        isIncrementDisabled={isIncrementDisabled}
        value={domainsCount}
        onIncrementClick={onIncrementClick}
        onDecrementClick={onDecrementClick}
        onInputChange={onChangeDomainsCountInput}
        error={error}
      />
    </Stack>
  );
};

export default AdminDomainsAllowed;
