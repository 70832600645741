import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, LazyIcon, LoadingStyled, TextField, Typography } from 'components';
import { Stack, Divider, SaveIcon, Button } from '@trustsecurenow/components-library';
import DomainsConfirmationDialog from './DomainsConfirmationDialog';
import AdminDomainsAllowed from './AdminDomainsAllowed';

const getDomainsCountErrorMessage = (domainsCount, min, max) => {
  if (domainsCount % 1 !== 0 || domainsCount.toString().includes('.')) {
    return 'Admins should not be able to enter decimal numbers.';
  }
  if (domainsCount < min) {
    return `Please enter a whole number above ${min}.`;
  }
  if (domainsCount > max) {
    return `Please enter a whole number below ${max}.`;
  }
  return '';
};

const AdminCapabilitiesDWBA = ({ data, loading, clearDwba, domainsRange, postDomainsRange, isUtProductType }) => {
  
  const { isEBPP } = useSelector(({ bsn }) => bsn?.user?.profile);

  const [domainsCount, setDomainsCount] = useState(domainsRange?.current);
  const [isPostingDomains, setIsPostingDomains] = useState(false);
  const [isDomainsConfirmationDialogOpen, setIsDomainsConfirmationDialogOpen] = useState(false);

  const domainsAllowedError = getDomainsCountErrorMessage(domainsCount, domainsRange?.min, domainsRange?.max)
  

  const handleCloseDomainsConfirmationDialog = () => {
    setIsDomainsConfirmationDialogOpen(false);
  }

  const handleOpenDomainsConfirmationDialog = () => {
    setIsDomainsConfirmationDialogOpen(true);
  }

  const handleSubmitDomainsConfimrationDialog = async () => {
    setIsPostingDomains(true);
    await postDomainsRange(Number(domainsCount));
    handleCloseDomainsConfirmationDialog();
    setIsPostingDomains(false);
  }

  const handleIncrementClick = () => {
    setDomainsCount(prevState => {
      const newValue = Math.trunc(Number(prevState) + 1);
      if (newValue <= domainsRange?.max) {
        return newValue;
      }
      return prevState;
    });
  };

  const handleDecrementClick = () => {
    setDomainsCount(prevState => {
      const newValue = Math.trunc(Number(prevState) - 1);
      if (newValue >= domainsRange?.min) {
        return newValue;
      }
      return prevState;
    });
  };

  const handleChangeDomainsCountInput = e => {
    const value = e.target.value;
    if(value.toString().length > 2) return;
    setDomainsCount(value);
  };

  if (loading) return (
    <Container.Grid justifyContent="center">
      <LoadingStyled />
    </Container.Grid>
  );

  return (
    <Container.Grid container md={12} spacing={2} pb={2} direction="column">
    <Container.Grid container md={12} spacing={4} pb={2} pt={2.6} flexWrap="wrap">
      {
        isUtProductType && (
          <Container.Grid item alignItems="center">
            <LazyIcon component="Disable" mr={2} color="colorDefault" size={1.6} />
            <Container.Grid direction="column">
              <Typography.h6 mr={2} mt={0.4} mb={0.4} whiteSpace='nowrap'>
                Licenses Allocated
              </Typography.h6>
              <Typography.p fontSize={12} mt={0.4} mb={0.4} whiteSpace='nowrap'>
                DWM Licenses Allocated
              </Typography.p>
            </Container.Grid>
            <TextField
              size="small"
              value={data?.licenses_allocated}
              disabled
            />
          </Container.Grid>
        )
      }
      {
        (data?.clear_dark_web_cache || data?.clear_allocated_licenses) && isUtProductType && (
          <Container.Grid item alignItems="center">
            {data?.clear_dark_web_cache && (
              <Button
                sx={{ mr: 2.25 }}
                onClick={(() => clearDwba('clear_dark_web_cache'))}
                disabled={loading}
              >
                Run Additional Scan
              </Button>
            )}

            {data?.clear_allocated_licenses && (
              <Button
                color='success'
                onClick={(() => clearDwba('clear_allocated_licenses'))}
                disabled={loading}
              >
                Clear Allocated Licenses
              </Button>
            )}
          </Container.Grid>
        )
      }
      {
        !isUtProductType && (
          <Container.Grid item alignItems="center">
            <AdminDomainsAllowed
              onChangeDomainsCountInput={handleChangeDomainsCountInput}
              onIncrementClick={handleIncrementClick}
              onDecrementClick={handleDecrementClick}
              domainsRange={domainsRange}
              domainsCount={domainsCount}
              isEBPP={isEBPP}
              error={domainsAllowedError}
            />
          </Container.Grid>
        )
      }
    </Container.Grid>
    {
      !isUtProductType && (
        
        <>
          <Divider /> 
          <Stack pt={2} alignItems='flex-end'>
            <Button
              color="info"
              startIcon={<SaveIcon />}
              disabled={Number(domainsCount) === Number(domainsRange?.current) || !!domainsAllowedError}
              onClick={handleOpenDomainsConfirmationDialog}
            >
              Save
            </Button>
          </Stack>
        </>
      )
    }
      <DomainsConfirmationDialog
          open={isDomainsConfirmationDialogOpen}
          domainsCount={domainsCount - domainsRange?.current}
          onClose={handleCloseDomainsConfirmationDialog}
          onSubmit={handleSubmitDomainsConfimrationDialog}
          isLoading={isPostingDomains}
        />
    </Container.Grid>
  );
};

export default AdminCapabilitiesDWBA;