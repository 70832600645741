// @flow
import { useState } from 'react';
import { hasKey } from 'helpers';

function useFormData() {
  const [formData, dispatch] = useState<Object>({});

  const setFormData = (e: Object) => {
    const el = hasKey(e, 'target') ? e.target : e;
    const data = {};
    const inputs = el.getElementsByTagName('input');
    const selects = el.getElementsByTagName('select');
    const textareas = el.getElementsByTagName('textarea');
    const formFields = [...inputs, ...selects, ...textareas];
    formFields.forEach(({ name, value, checked }) => {
      if (name !== '') {

        //Make tag_id null if it's empty
        if (name == 'tag_id' && value == '') {
          data['tag_id'] = null
          return;
        }
        
        data[name] = value === 'true' || value === 'false' || typeof value === 'boolean' ? checked : value;
        dispatch(data);
      }
    });
    return data;
  };

  return { formData, setFormData };
}

export default useFormData;
