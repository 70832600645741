import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  IconButton,
  Typography,
  CloseIcon,
  Link,
  Stack,
  Box
} from '@trustsecurenow/components-library';

function DomainsConfirmationDialog({ domainsCount, open, onClose, onSubmit, isLoading }) {
  const isAddingDomains = domainsCount >= 0;

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle component={Stack} alignItems="flex-end">
        <IconButton onClick={onClose}>
          <CloseIcon sx={{ fontSize: 22 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" pb={5.75}>
          <Typography variant="h2" textAlign="center">
            Are you sure you would like to<br />add additional domains for this account?
          </Typography>
          <Typography variant="subtitle" textAlign="center" mt={2}>
            {Math.abs(domainsCount)} { isAddingDomains && 'additional' } domain(s) will be { isAddingDomains ? 'added.' : 'removed.' }
          </Typography>
          <Box mt={3} mb={3.5}>
            <Button color="success" width={170} onClick={onSubmit} loading={isLoading}>
              Yes, Continue
            </Button>
          </Box>
          <Link component="button" onClick={onClose}>
            Cancel
          </Link>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default DomainsConfirmationDialog;
